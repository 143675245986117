
.admin-dashboard {
    margin-top: 50px !important;
    .dashboard-6 {
        margin-bottom: 20px;
        padding-right: 10px;
    }
}
.dashboard-card {
    height: 85px;
    padding: 20px 30px 20px 20px;
    
    box-shadow: 0 20px 40px 10px rgb(224 224 224 / 20%);
    background-color: $white-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .dashboard-card-detail {
        display: flex;
        align-items: center;
        img {
            width: 45px;
            height: 45px;
            margin: 0 20px 0 0;
            padding: 12px;
            
            background-color: rgb(32 32 32 / 10%);
            object-fit: contain;
        }
        span {
            font-size: $font-size14;
            font-weight: $font-medium;
            color: $heading-color;
            &.dashboard-title {
                position: relative;
                top: 3px;
            }
        }
        .dashboard-store {
            button {
                background-color: transparent;
                border: 0;
                font-size: $font-size12;
                font-weight: $font-medium;
                color: $text-color;
                margin: 0;
                padding: 0;
                position: relative;
                top: -3px;
            }
        }
    }
    .perfume-count {
        padding: 0 45px 0 30px;
        border-left: 1px solid #d8d8d8;
        width: 44px;
        h3 {
            font-size: $font-size25;
            font-weight: $font-bold;
            color: $heading-color;
            margin-bottom: 0;
        }
    }
}
.report-dashboard {
    margin-top: 40px !important;
    .dashboard-6 {
        margin-bottom: 20px;
        padding-right: 7px;
        .dashboard-card-detail {
            img {
                margin: 0 15px 0 0;
            }
            .dashboard-store {
                line-height: 1.36;
            }
        }
        .dashboard-card {
            padding: 20px 15px 20px 20px;
        }
        .report-count {
            padding-left: 15px;
            border-left: 1px solid #d8d8d8;
            h3 {
                font-size: $font-size25;
                font-weight: $font-bold;
                color: $heading-color;
                margin-bottom: 0;
            }
        }
    }
}
