.auth-wrapper {
    .auth-row {
        align-items: center;
        margin: 0;
    }
    .authbanner {
        background: url('/images/auth-img.jpg') center no-repeat;
        background-size: cover;
        background-position: center;
        height: 100vh;
        .auth-pic {
            padding: 30px 0 0 20px;
        }
    }
    .form-wrap {
        padding: 0 120px;
        .sign-in-heading {
            margin-bottom: 30px;
            h3 {
                margin: 0 0 5px 0;
                font-family: $font-bold;
                font-size: $auth-heading;
                font-weight: $font-bold;
                color: $heading-color;
                text-transform: uppercase;
            }
            .auth-short-des {
                font-size: 15px;
                font-weight: $font-reg;
                font-style: normal;
                color: $text-color;
                margin-bottom: 0;
                line-height: 1.53;
                letter-spacing: normal;
                width: 335px;
            }
            .email-short-instruction {
                font-size: 15px;
                font-weight: $font-reg;
                font-style: normal;
                color: $text-color;
                line-height: 1.53;
                letter-spacing: normal;
                width: 345px;
                margin: 10px auto 0;
            }
        }
    }
}
.remember-me {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .form-check-input[type=checkbox] {
        border-radius: 0;
        border: 2px solid #6f6f6f;
        &:focus {
            border-color: 0;
            outline: 0;
            box-shadow: unset;
        }
    }
    label {
        color: $heading-color;
        font-size: $font-size14;
    }
    a {
        color: $heading-color;
        text-decoration: underline;
        font-size: $font-size14;
    }
}
.auth-btn {
    button {
        font-weight: $font-bold;
        font-size: $font-size14;
    }
}
.email-sent {
    margin-bottom: 20px;
}
.p-des {
    color: $heading-color;
    font-size: $font-size14;
}
