/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "../src/assets/scss/main.scss";
// .loader {
//     border: 16px solid #f3f3f3;
//     border-radius: 50%;
//     border-top: 16px solid #3498db;
//     width: 120px;
//     height: 120px;
//     -webkit-animation: spin 2s linear infinite; /* Safari */
//     animation: spin 2s linear infinite;
//   }

*{
  font-family: "IBM Plex Sans Arabic", sans-serif;
  font-family: "Source Sans 3", sans-serif;
}
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }